/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // Add global gatsby stylesheet.
 import './src/styles/global.css';

// You can delete this file if you're not using it
